<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }" v-if="currentCategory!=null" >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Tag
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Radio Name"
            rules="required"
          >
            <b-form-group
              label="Radio Name"
              label-for="radio-name"
            >
              <b-form-input
                id="radio-name"
                v-model="currentCategory.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Radio Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        
             <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Subtitle"
           
          >
            <b-form-group
              label="Subtitle"
              label-for="subtitle"
            >
              <b-form-input
                id="subtitle"
                v-model="currentCategory.subtitle"
              
                   placeholder="Subtitle"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="Contact Email"
            rules="required"
          >
            <b-form-group
              label="Contact Email"
              label-for="contact-email"
            >
              <b-form-input
                id="contact-email"
                v-model="currentCategory.contactEmail"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Contact Email"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Stream Url" 
          > 
            <b-form-group
              label="Stream Url"
              label-for="streamurl"

            >
              <b-form-input
                id="streamurl"
                v-model="currentCategory.defaultURL"
             
                   placeholder="Stream Url"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label="Radio Logo (500x500)"
              label-for="radio-logo"
            >
          <b-media no-body>
          <b-media-aside>
      
          <b-img
            ref="previewEl"
            rounded
            :src="currentCategory.logo"
            height="80"
               width="80"
          />
          <b-media-body class="mt-75 ml-75">
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
           :state="Boolean(file)"
          v-model="file"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
          </b-media-body>
       
        <!--/ avatar -->
      </b-media-aside>
          </b-media>
          </b-form-group>



          <b-col md="12">
          <b-form-group
            label="Logo Style"
            label-for="isSecured"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="currentCategory.rounded_logo"  name="rounded_logo" v-bind:value="true" >
              Rounded
            </b-form-radio>
            <b-form-radio v-model="currentCategory.rounded_logo"   name="rounded_logo" v-bind:value="false" >
              Square
            </b-form-radio>
          </div>
          </b-form-group>
        </b-col>
        
        <b-col md="12">
        <b-form-group
            label="Background Option"
            label-for="isSecured"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="currentCategory.backgroundOption"  name="background_option" v-bind:value="99" >
             Default
            </b-form-radio>
            <b-form-radio v-model="currentCategory.backgroundOption"  name="background_option" v-bind:value="1" >
              Background Image
            </b-form-radio>
            <b-form-radio  v-model="currentCategory.backgroundOption"  name="background_option" v-bind:value="0" >
              Background Solid Color
            </b-form-radio>
            <b-form-radio  v-model="currentCategory.backgroundOption"  name="background_option" v-bind:value="2" >
              Gradient Background Color
            </b-form-radio>
          </div>
          </b-form-group></b-col>
          <b-col md="12" v-if="currentCategory.backgroundOption==1">
        <b-form-group
            label-for="back_logo"
            label="Background Image"
          >
       
          <b-media no-body>
          <b-media-aside>
      
          <b-img
            ref="backpreviewEl"
            rounded
            :src="currentCategory.background_image"
            height="80"
               width="80"
          />
          <b-media-body class="mt-75 ml-75">
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
           :state="Boolean(backfile)"
          v-model="backfile"
          class="mb-75 mr-75"
          @click="$refs.refBgInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refBgInputEl"
          v-model="backprofileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="backImageSelected"
        />
          <!-- reset -->
          <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
           @click="resetBackImage()"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
    
          </b-media-body>
       
        <!--/ avatar -->
      </b-media-aside>
          </b-media>
          </b-form-group>
        </b-col>
        <b-col class="mt-1  ml-1 mr-1" md="12"  v-if="currentCategory.backgroundOption==0">
        
        
    
        <div>
       
       
            <b-form-group
              label="Background Color"
              label-for="background-color"
            >
              <b-form-input
                id="background-color"
                v-model="currentCategory.background_color"
                autofocus
                placeholder="#eeeeee"
                trim
               
              />

            
            </b-form-group>
         

      </div>
      
     

      
      </b-col>
      <b-col class="  ml-1 mr-1" md="12"  v-if="currentCategory.backgroundOption==2">
        
     
          
        <b-form-group
              label="Start Color"
              label-for="gradient_start_color"
            >
              <b-form-input
                id="gradient_start_color"
                v-model="currentCategory.gradient_start_color"
                autofocus
                placeholder="#eeeeee"
                trim
               
              />

            
            </b-form-group>
       
         
            <b-form-group
              label="End Color"
              label-for="gradient_end_color"
            >
        <b-form-input
                id="gradient_end_color"
                v-model="currentCategory.gradient_end_color"
                autofocus
                placeholder="#eeeeee"
                trim
               
              />
            </b-form-group>
       
        

 
        
        <b-form-group
          label-for="terms_url"
          label="Gradient Type"
        >
        
        <v-select 
        v-model="currentCategory.gradient_type"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="gradient type"
        :options="gradient_types" 
        
        class="select-size-lg">
        
      </v-select>
         
          
       
        </b-form-group>

    
        
        <b-form-group
          label-for="terms_url"
          label="Gradient Direction - Start Position"
        >
        
        <v-select  
        v-model="currentCategory.gradient_start"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="Direction"
        :options="gradient_directions" 
        
        class="select-size-lg">
        
      </v-select>
     
    </b-form-group>
      <b-form-group
          label-for="terms_url"
          label="Gradient Direction - End Position"
        >
        <v-select  
        v-model="currentCategory.gradient_end"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="Direction"
        :options="gradient_directions" 
        
        class="select-size-lg">
        
      </v-select>
     
        </b-form-group>

      
      </b-col>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="currentCategory==null|| currentCategory.id==null"
            >
                     Add
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updatecategory"
            v-if="currentCategory!=null&&currentCategory.id!=null"
            >
                      Update 
            </b-button>

              <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                  class="mr-2"
                  v-if="currentCategory!=null&& currentCategory.id!=null"
              variant="outline-danger"
              @click="deletecategory"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
    BCol,
    BCard, BFormRadio,BFormFile, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardText,BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import DepartmentPicker from '../components/DepartmentPicker.vue'
import Attachment from "./Attachment.vue";
import axios from "@axios"; 
import LvColorpicker from 'lightvue/color-picker';

export default {
  components: {
    BSidebar,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormRadio,
    vSelect,
    BCardText,
    BMedia, BMediaAside, BMediaBody, BLink, BImg,
    Attachment,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DepartmentPicker,
    LvColorpicker 
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  methods:{
    resetSplash(){
      this.splashprofileFile= null;
      this.splashfile= null;
      this.currentCategory.splash_logo=null;

    },
    resetNavlogo(){
      this.navprofileFile= null;
      this.navfile= null;
      this.currentCategory.nav_logo=null;

    },
    resetBackImage(){
      this.backprofileFile= null;
      this.backfile= null;
      this.currentCategory.background_image=null;

    },

    backImageSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.currentCategory.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.currentCategory.background_image= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.backfile = null;
            this.backprofileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
    navLogoSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.currentCategory.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.currentCategory.nav_logo= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.navfile = null;
            this.navprofileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
    fileSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.currentCategory.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.currentCategory.splash_logo= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.spalshfile = null;
            this.splashprofileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
     resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    refetchData(){
            console.log("Refecth in Uploader");
            this.$emit('refetch-data')
          
        },
        updateData(data)
        {
          console.log(data);
          this.currentCategory.logo=data;
        },
        uploadFile(paylod) {
          return;

        this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
      },
      fileBackSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.currentCategory.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.currentCategory.backgroundImage= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.bgfile = null;
            this.profileBgFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
      fileSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.currentCategory.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.currentCategory.logo= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.file = null;
            this.profileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
    onDepartmentSelectionChanged(payload)
    {
      console.log("currentCategory selected");
      console.log(payload);
      this.currentCategory.upperCategory=payload.id;
this.currentCategory.Parent=payload ;
    },
     selectionChanged(selectedGroup){
       // console.log(selectedGroup);

       if (selectedGroup==null||selectedGroup==undefined)
       {
   this.currentCategory.upperCategory = null;
       }else
       {
   this.currentCategory.upperCategory = selectedGroup.id
       }
   
    },
    deletecategory(){
      this.currentCategory.deleted=true;

      this.$store.dispatch('apps-radiolisting/deleteradio', this.currentCategory)
        .then((result) => {

          console.log(result);
        this.$emit('refetch-data')
        this.$emit('update:is-add-new-user-sidebar-active', false)
        
      });
    },
    updatecategory(){
     console.log("sent data");
     console.log(this.currentCategory);

      this.$store.dispatch('apps-radiolisting/updateblogtag', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },

    submitForm(){
 
    console.log(this.currentCategory);
    
       this.$store.dispatch('apps-radiolisting/createDepartment', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    }
  },
  
  watch: {
     categorydata:   {
        handler:function (n, o)   {
          console.log('new client: ', n);

                  if (n==null||n==undefined)
                  {
                    this.currentCategory={
                      id:null,
                      name:null,
                      description:null,
                       upperCategory: null,
                      Parent:null
                    }
                  } else
                  { 
                    console.log('new client: ', n.selectedCategory);
                  this.currentCategory = n.selectedCategory;
                  }
        

        },
        deep: true
    },
    currentCategory:   {
        handler:function (n, o)   {
          console.log('appseting changed in watch ', n);

                  
                    console.log('new client: ', n);
                    this.colorPickerKey++;
             
        

        },
        deep: true
    }
    
},
  
  data() {
    return {
      gradient_types:['LINEAR','RADIAL','SWEEP','DIAMOND'],
      gradient_directions:["topLeft" , "topCenter"  , "topRight" ,"centerLeft" ,"center" , "centerRight" ,"bottomLeft" , "bottomCenter", "bottomRight" ],
      
      required,
      alphaNum,
      email,
      countries,
      currentCategory :null,
      profileFile: null,
      file: null,
      profileBgFile: null,
      bgfile: null,
      colorPickerKey: 0, 
     splashprofileFile: null,
     splashfile: null,
     navfile: null,
     navprofileFile: null,
     backfile: null,
     backprofileFile: null,
      isUpdated: false,
          isLoading: false,
    }
  },
   inject: ['categorydata'],
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      upperCategory: '',
      description: '',
      parent:null
       
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('apps-radiolisting/createDepartment', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
