import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/public/change-password',
      name: 'change-password',
      component: () => import('@/views/public/change_pass.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/public/forgot-password',
      name: 'forget-password',
      component: () => import('@/views/public/forget_pass.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/notification-center',
      name: 'notification-center',
      component: () => import('@/views/crm/notificationcenter/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Notification Center',
        breadcrumb: [
          {
            text: 'Notifications',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-wishlist/:id/:radioTitle',
      name: 'radio-wishlist',
      component: () => import('@/views/radio/wishlist/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Wishlist',
        breadcrumb: [
          {
            text: 'Wishlist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-listing',
      name: 'RadioList',
      component: () => import('@/views/radio/channels/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Radio Channels',
        breadcrumb: [
          {
            text: 'Radio Channels',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-settings',
      name: 'RadioSettings',
      component: () => import('@/views/radio/radio-settings/RadioSettings.vue'),
      meta: {
        pageTitle: 'Radio Settings',
        breadcrumb: [
          {
            text: 'Radio Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-schedule/:id/:radioTitle',
      name: 'radio-schedule',
      component: () => import('@/views/radio/schedule/Schedule.vue'),
      meta: {
        pageTitle: 'Weekly Schedule',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Weekly Schedule',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-frequencies/:id/:radioTitle',
      name: 'radio-frequencies',
      component: () => import('@/views/radio/frequencies/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Frequencies',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Frequencies',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-toplists/:id/:radioTitle',
      name: 'radio-toplists',
      component: () => import('@/views/radio/toplists/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Toplists',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Toplists',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-sponsors/:id/:radioTitle',
      name: 'radio-sponsors',
      component: () => import('@/views/radio/sponsors/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Sponsors',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Sponsors',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-programs/:id/:radioTitle',
      name: 'radio-programs',
      component: () => import('@/views/radio/programs/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Programs',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Programs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-hosts/:id/:radioTitle',
      name: 'radio-hosts',
      component: () => import('@/views/radio/presenters/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Hosts',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Hosts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/radio-podcasts/:id/:radioTitle',
      name: 'radio-podcasts',
      component: () => import('@/views/radio/podcasts/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Podcasts',
        breadcrumb: [
          {
            text: 'Radios',
            to: '/radio-listing',
          },
          {
            text: 'Podcasts',
            active: true,
          },
        ],
      },
    },
          
   
     
    {
      path: '/library',
      name: 'library-page',
      component: () => import('@/views/miscellaneous/ComingSoon.vue'),
      meta: {
        pageTitle: 'library',
        breadcrumb: [
          
          {
            text: 'Library',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blogs',
      name: 'cms-blogs',
      component: () => import('@/views/cms/blogs/department-list/BlogList.vue'),
      meta: {
        pageTitle: 'Posts',
        breadcrumb: [
          {
            text: 'Posts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blogpost-new',
      name: 'blogpost-new',
      component: () => import('@/views/cms/blogs/department-edit/BlogNew.vue'),
      meta: {
        pageTitle: 'New Post',
        breadcrumb: [
          {
            text: 'Create New Post',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-edit',
      name: 'cms-blog-edit',
      component: () => import('@/views/cms/blogs/department-edit/BlogEdit.vue'),
      meta: {
        pageTitle: 'Post Edit',
        breadcrumb: [
          {
            text: 'Post Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-categories',
      name: 'cms-blog-categories',
      component: () => import('@/views/cms/blogcategories/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Post Categories',
        breadcrumb: [
          {
            text: 'Post Categories',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-tags',
      name: 'cms-blog-tags',
      component: () => import('@/views/cms/blogtags/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Post Tags',
        breadcrumb: [
          {
            text: 'Post Tags',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-micro-cms',
      name: 'cms-micro-cms',
      component: () => import('@/views/cms/microcms/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Micro Content',
        breadcrumb: [
          {
            text: 'Micro Content',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-publishers',
      name: 'cms-blog-publishers',
      component: () => import('@/views/cms/blogpublishers/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Publishers',
        breadcrumb: [
          {
            text: 'Publishers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-library',
      name: 'cms-library',
      component: () => import('@/views/cms/library/library-list/LibraryList.vue'),
      meta: {
        pageTitle: 'Library',
        breadcrumb: [
          {
            text: 'Library',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-library-categories',
      name: 'cms-library-categories',
      component: () => import('@/views/cms/librarycategories/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Library Categories',
        breadcrumb: [
          {
            text: 'Library Categories',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/choosecompany',
      name: 'choosecompany',
      component: () => import('@/views/choosecompany.vue'),
      props: route => ({

        ...route.params,
      }),
      meta: {
        layout: 'full',
      },
    },
    
    
    {
      path: '/admin-users-page',
      name: 'admin-users-page',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Admin Users',
            active: true,
          },
        ],
      },
    },
   
    {
      path: '/admin-users-view',
      name: 'admin-users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Users',
            to: 'admin-users-page',
          },
          {
            text: 'User Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-users-edit',
      name: 'admin-users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Users',
            to: 'admin-users-page',
          },
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/settings',
      name: 'settings-page',
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Settings',
          },
          {
            text: 'General',
            active: true,
          },
        ],
      },
    },
     
    
    {
      path: '/cms-pages',
      name: 'cms-pages',
      component: () => import('@/views/cms/pages/pages-list/PagesList.vue'),
      meta: {
        pageTitle: 'Pages',
        breadcrumb: [
          {
            text: 'Pages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-pages-edit',
      name: 'cms-pages-edit',
      component: () => import('@/views/cms/pages/page-edit/BlogEdit.vue'),
      meta: {
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            text: 'Edit Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-pages-new',
      name: 'cms-pages-new',
      component: () => import('@/views/cms/pages/page-edit/BlogNew.vue'),
      meta: {
        pageTitle: 'New Page',
        breadcrumb: [
          {
            text: 'New Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-feedbacks',
      name: 'cms-feedbacks',
      component: () => import('@/views/cms/feedbacks/pages-list/PagesList.vue'),
      meta: {
        pageTitle: 'Feedbacks',
        breadcrumb: [
          {
            text: 'Feedbacks',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-suggestion-detail',
      name: 'cms-suggestion-detail',
      component: () => import('@/views/cms/sugestions/participant-edit/ParticipantsEdit.vue'),
      meta: {
        pageTitle: 'Suggestion Detail',
        breadcrumb: [
          {
            text: 'Suggestions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-suggestions',
      name: 'cms-suggestions',
      component: () => import('@/views/cms/sugestions/participant-list/ParticipantsList.vue'),
      meta: {
        pageTitle: 'Suggestions',
        breadcrumb: [
          {
            text: 'Suggestions',
            active: true,
          },
        ],
      },
    },
    

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  console.log('logged in ',to)

    if(to.path.includes('public/')) 
    {
      console.log("next trigger");
     return  next();
    } 
    else

    {
      console.log("path does not includes");
      console.log(to.path);

    }

  const isLoggedIn = isUserLoggedIn()
  //   console.log("forward");

  //   var forweardToLogin= localStorage.getItem('needstogoLogin');
  //  console.log("forweardToLogin "+forweardToLogin);

  //   if (forweardToLogin === "true")
  //   {
  //     console.log("in forward logged in ");
  //     if (  to.name !== 'login') {
  //       localStorage.setItem('needstogoLogin',false)
  //       console.log("in change root to logged in ");
  //       return next({ name: 'login' })
  //     }
  //   }

  console.log('isLoggedIn', isLoggedIn)
  console.log('Step 0')

  if (isLoggedIn == null && (to.name === 'login' || to.name === 'choosecompany')) {
    console.log('Step 1')

    next()
  } else if (isLoggedIn == null && to.name !== 'login') {
    console.log('Step 2')
    return next({ name: 'login' })
  } else
  if (isLoggedIn === false && (to.name !== 'login' || to.name !== 'choosecompany')) {
    console.log('Step 3')
    return next({ name: 'login' })
  } else {
    console.log('Step 4')

    return next()
  }

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
