import jwtDefaultConfig from './jwtDefaultConfig'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
      console.log('JwtService constructor');
      console.log(jwtOverrideConfig);
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
       
        var host=process.env.VUE_APP_API_URL;
       
        config.baseURL = host;
        console.log(host);
        
        config.headers = {
          'Client-Auth-Token': this.getClientAuthToken(),
        };
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
        //  config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        config.headers = {
          'X-access-token': `${this.jwtConfig.tokenType} ${accessToken}`,
          'Client-Auth-Token': this.getClientAuthToken(),
          'Content-Language': this.getContentLocalization(),
          'Lang': this.getAppLocalization(),
        }  
      }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
  //  return  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywicmVxdWVzdGlkIjoiJDJhJDEwJHJMeTZKcC5xZUEzakN3Sm54U1hNcS5CY25UZnhEQVZuR0ZIbHk0NkEyWTY2SHpUZTRxR2hHIiwiaWF0IjoxNjQ3NjM4MDk5LCJleHAiOjE2NDc3MjQ0OTl9.Np3a0Bhvv_L8Q4mNGRSsGhYOoacsqFdyOVHxNn7tf6E";
   return  localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }
  getContentLocalization() {
    return localStorage.getItem("CURRENT_LANGUAGE")
  }
  getAppLocalization() {
    return localStorage.getItem("APP_LANGUAGE")
  }
  getClientAuthToken() {
    return localStorage.getItem("clientToken")
  }
  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setClientLogo(value) {
    localStorage.setItem(this.jwtConfig.storageClientLogoKeyName, value)
  }
  setcustomlogin(value) {
    localStorage.setItem(this.jwtConfig.storageclientCustomLoginKeyName, value)
  }
  getcustomlogin() {
    //  return  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywicmVxdWVzdGlkIjoiJDJhJDEwJHJMeTZKcC5xZUEzakN3Sm54U1hNcS5CY25UZnhEQVZuR0ZIbHk0NkEyWTY2SHpUZTRxR2hHIiwiaWF0IjoxNjQ3NjM4MDk5LCJleHAiOjE2NDc3MjQ0OTl9.Np3a0Bhvv_L8Q4mNGRSsGhYOoacsqFdyOVHxNn7tf6E";
     return  localStorage.getItem(this.jwtConfig.storageclientCustomLoginKeyName)
    }
  getClientLogo() {
    //  return  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywicmVxdWVzdGlkIjoiJDJhJDEwJHJMeTZKcC5xZUEzakN3Sm54U1hNcS5CY25UZnhEQVZuR0ZIbHk0NkEyWTY2SHpUZTRxR2hHIiwiaWF0IjoxNjQ3NjM4MDk5LCJleHAiOjE2NDc3MjQ0OTl9.Np3a0Bhvv_L8Q4mNGRSsGhYOoacsqFdyOVHxNn7tf6E";
     return  localStorage.getItem(this.jwtConfig.storageClientLogoKeyName)
    }
  


  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }
  
  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  iForget(...args) {
    return this.axiosIns.post('/admin/auth/forgot-password', ...args, {
     
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }
  updatePass(...args) {
    return this.axiosIns.post('/admin/auth/update-password', ...args, {
      
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }

  getModules(...args) {
    return this.axiosIns.get(this.jwtConfig.moduleEndpoint, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }

  logincompany(...args) {
    return this.axiosIns.post(this.jwtConfig.logincompanyEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }


  getUsers(...args) {
    return this.axiosIns.get(this.jwtConfig.getUsers, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }  
  getUsersByRole(...args) {
    return this.axiosIns.get(`this.jwtConfig.getUsersByRole/${id}`, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  } 

  
  

  addUser(...args) {
    return this.axiosIns.post(this.jwtConfig.addUser, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }    

  refreshToken() {
    console.log("refresh token launched 2");
    localStorage.setItem('needstogoLogin',true)
            
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    // Remove userData from localStorage
    localStorage.removeItem('userData');
    
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getCategories(...args) {
    return this.axiosIns.get(this.jwtConfig.getCategories, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  } 
  
  addCategory(...args) {
    return this.axiosIns.post(this.jwtConfig.addCategory, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }  
  
  getCourses(...args) {
    return this.axiosIns.get(this.jwtConfig.getCourses, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }
  
  addCourse(...args) {
    return this.axiosIns.post(this.jwtConfig.addCourse, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }

  getCourseDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.getCourse}/${id}`, {}, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }  

  updateCourse(...args) {
    return this.axiosIns.put(this.jwtConfig.updateCourse, ...args, {
      'X-access-token': this.getToken(),
      'Client-Auth-Token': this.getClientAuthToken(),
    })
  }    

}
